import { graphql, useStaticQuery } from "gatsby";
import React from 'react'

import Hero from "../components/sectors-page/hero/Hero.js";
import Layout from '../components/layout/Layout'
import Seo from '../components/seo'
import Description from "../components/sectors-page/description/Description";
import SectionZ from "../components/sectors-page/sectionZ/SectionZ";

const SectorsPage = (props) => {
    const {strapiSectors} = useStaticQuery(query);

    return (
        <Layout path={props.location.pathname}>
            <Seo
                title={strapiSectors.metaTitle}
                description={strapiSectors.metaDescription}
                keywords={strapiSectors.metaKeywords}
                />
            <Hero path={props.location.pathname}/>
            <Description />
            <SectionZ />
        </Layout>
    )
}

export default SectorsPage

const query = graphql`
    query {
        strapiSectors {
            metaTitle
            metaDescription
            metaKeywords
        }
    }
`
