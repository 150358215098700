import React from "react";
import styled from "styled-components";

const Description = () => {
  return (
    <DescriptionStyle>
      <H2>
        Medad Technology's ultrasonic delivery system revolutionizes HealthTech
        with scalable applications in consumer and pharmaceutical markets
      </H2>
      <P>
        The core system has 3 key product applications that provide cutting-edge
        solutions to tackle tobacco dependance and improve drug delivery, with
        design scalability to meet diverse market needs.
      </P>
    </DescriptionStyle>
  );
};

export default Description;

const DescriptionStyle = styled.div`
  padding-top: 29.1rem;
  padding-bottom: 29.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12rem;
  background: linear-gradient(
    11deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 49%,
    rgba(149, 144, 217, 0.5) 100%
  );
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 10rem 2.4rem;
    gap: 3rem;
  }
`;

const H2 = styled.h2`
  font-family: Sequel75;
  font-size: 3.2rem;
  line-height: 5.4rem;
  max-width: 73.9rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 2rem;
    line-height: 2.7rem;
    br {
      display: none;
    }
  }
`;

const P = styled.p`
  font-weight: 400;
  font-family: Sequel55;
  font-size: 1.8rem;
  line-height: 2.4rem;
  max-width: 43.9rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    line-height: 2.2rem;
  }
`;
