import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import MedadButton from "../../library/Button";

import healthTechImg from "../../../../static/images/sectors-page/section-z/healthtech.jpg";
import bioTechImg from "../../../../static/images/sectors-page/section-z/biotech.jpg";
import finTechImg from "../../../../static/images/sectors-page/section-z/fintech.jpg";
import powerManagementImg from "../../../../static/images/sectors-page/section-z/power-management.jpg";

const SectionZ = () => {
  return (
    <SectionZStyle>
      <LayoutOdd>
        <ImageArea img={healthTechImg} />
        <DescriptionArea>
          <Wrapper>
            <H3>
              Consumer HealthTech
              <br />
              Ultrasonic E-cigarette
            </H3>
            <P>
              Our ultrasonic delivery system can be used to deliver nicotine as
              an ultrasonic e-cigarette. Why use ultrasound for nicotine
              delivery? The burning or heating of tobacco and nicotine at high
              temperatures leads to the formation of toxic chemicals that cause
              diseases. Using ultrasound removes the need to heat nicotine
              e-liquid, eliminating toxic chemicals and reducing potential harm.
              Instead of a vapour, the e-liquid is aerosolized into a cool,
              clean mist.
              <br />
              <br />
              Our 'misting' technology provides an opportunity for the
              introduction of a novel category of reduced-risk, smoke-free
              products that can meet regulatory requirements and further support
              Tobacco Harm Reduction initiatives. It also has the potential to
              address nicotine abuse liability concerns. E-cigarettes are less
              efficient at delivering nicotine which can lead to overuse, or
              paradoxically, drive users back to combustible cigarettes. Our
              proprietary algorithm controls particle size, resulting in a
              narrow particle distribution that optimizes aerosol delivery to
              the alveoli. This has the potential to improve product
              satisfaction with reduced nicotine concentrations.
              <br />
              <br />
              The escalating youth vaping epidemic, driven by appealing flavours
              and accessibility, along with a surge in illicit and counterfeit
              products, presents a significant challenge and raises safety
              concerns. Our technology addresses these issues with an in-app age
              verification process which requires an ID check for device
              activation. To safeguard against counterfeit products, we use our
              proprietary pod authentication system with an OTP chip.
              <br />
              <br />
            </P>
            {/* <MedadButton
              primary
              type={"button"}
              onClick={() => navigate("/sector/healthtech")}
              label={"Learn more"}
            /> */}
          </Wrapper>
        </DescriptionArea>
      </LayoutOdd>
      <LayoutEven>
        <ImageArea img={bioTechImg} />
        <DescriptionArea>
          <Wrapper>
            <H3>
              Pharmaceutical HealthTech
              <br />
              Nicotine Replacement Therapy
            </H3>
            <P>
              Existing Nicotine Replacement Therapies (NRTs) are safe but fail
              to deliver nicotine rapidly or satisfy the habitual, sensorial
              cravings, reducing its effectiveness as a smoking cessation aid.
              As a result, NRTs are losing popularity, with many smokers turning
              to e-cigarettes as a solution for quitting smoking. E-cigarettes
              are proving to be effective but raise safety concerns, especially
              with the rise of unregulated products entering the market.
              Although toxic emissions are significantly reduced, thermal
              degradation of the e-liquid does occur, and further additives
              found in unlicensed products could increase the risk of potential
              harm.
              <br />
              <br />
              Smokers need access to an improved NRT that offers the same safety
              profile but is as effective as e-cigarettes. With our ultrasonic
              nicotine delivery system (UNDS), we deliver nicotine more
              efficiently than traditional NRTs and e-cigarettes, whilst
              delivering on sensorial cues through inhalation, improving
              quitting success rates.
              <br />
              <br />
              Our UNDS comes with a digital companion app that is available on
              IOS and Android for monitoring and behavourial support. It can
              track consumption data such as number of inhalations and provide
              push notifications to help users manage their habit. Research
              indicates that the efficacy of tobacco dependence treatments is
              enhanced when smoking cessation aids are coupled with behavioral
              therapy. We have partnered with one of the world's leading medical
              establishments, Mayo Clinic, and their Foundation for Medical
              Education & Research, to explore the development of new treatments
              and innovative strategies for treating tobacco users, leveraging
              digital strategies and interventions.
            </P>
            {/* <MedadButton
              primary
              type={"button"}
              onClick={() => navigate("/sector/biotech")}
              label={"Learn more"}
            /> */}
          </Wrapper>
        </DescriptionArea>
      </LayoutEven>
      <LayoutOdd>
        <ImageArea img={finTechImg} />
        <DescriptionArea>
          <Wrapper>
            <H3>
              Pharmaceutical HealthTech
              <br />
              Smart Nebulizer
            </H3>
            <P>
              High quality healthcare for complex conditions is intricate and
              highly personal. However, existing treatments are often
              impersonal, generic and in the less effective form of a pill. Oral
              medications and existing inhaler treatments are not fast acting as
              the medication needs to be absorbed into the blood stream before
              taking effect, has limited dosing options and side effects.
              <br />
              <br />
              Existing inhaler technologies target the 1st, 2nd and 3rd
              generation airways in the lungs which is effective for the
              treatment of respiratory diseases. Targeting the 4th generation
              airways leads directly into the alveolar spaces which can provide
              efficacy outside of the respiratory tract through systemic
              exposure. This requires a particle size below 1µm.
              <br />
              <br />
              With our technology and algorithm, we can control the particle
              size and distribution to directly target the 4th generation
              airways, for extra-pulmonary efficacy. Delivering through the
              alveolar in the lungs directly into oxygenated blood negates the
              effects of metabolism on drug delivery, improving efficacy and
              reducing unwanted side effects. Systemic delivery also provides an
              opportunity for treatment of disease outside of the respiratory
              tract.
            </P>
            {/* <MedadButton
              primary
              type={"button"}
              onClick={() => navigate("/sector/fintech")}
              label={"Learn more"}
            /> */}
          </Wrapper>
        </DescriptionArea>
      </LayoutOdd>
      {/* <LayoutEven>
        <ImageArea img={powerManagementImg} />
        <DescriptionArea>
          <Wrapper>
            <H3>POWER MANAGEMENT</H3>
            <P>
              EV Batteries struggle with long charging times and low range. Our
              new generation of wirelessly charged electric vehicle batteries
              address these problems.
            </P>
            <MedadButton
              primary
              type={"button"}
              onClick={() => navigate("/sector/power-management")}
              label={"Learn more"}
            />
          </Wrapper>
        </DescriptionArea>
      </LayoutEven> */}
    </SectionZStyle>
  );
};

export default SectionZ;

const SectionZStyle = styled.div`
  padding-bottom: 7.9rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding-bottom: 0.4rem;
  }

  button {
    margin-top: 4rem;
    @media ${({ theme }) => theme.breakpoints.mobile} {
      margin-top: 2rem;
      width: fit-content;
      padding: 1.8rem 2.6rem;
    }
  }
`;

const Wrapper = styled.div``;

const LayoutOdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const LayoutEven = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const ImageArea = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 80vh;
  //flex: 1;
  width: 30%;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    height: 50vh;
    flex: unset;
    width: 100vw;
  }
`;

const DescriptionArea = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex: unset;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    padding-bottom: 3.6rem;

    p {
      font-size: 1.6rem;
    }
  }
`;

const H3 = styled.h3`
  font-size: 3.2rem;
  line-height: 5.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 2rem;
    line-height: 2.7rem;
  }
`;

const P = styled.p`
  margin-top: 4.44rem;
  font-family: Sequel45;
  font-size: 1.8rem;
  line-height: 2.6rem;
  max-width: 100rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 2.4rem;
  }
`;
